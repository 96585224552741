
import { defineComponent, ref, reactive } from "vue";
import {
	IonPage,
	IonContent,
	IonText,
	IonSlides,
	IonSlide,
	IonImg,
	IonList,
	IonItem,
	IonLabel,
	IonInput,
	IonTextarea,
	IonRow,
	IonCol,
	//IonButton,
	IonIcon,
	//IonListHeader,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonSpinner,
	onIonViewWillEnter,
} from "@ionic/vue";
import {
	heartOutline,
	heart,
	call,
	remove,
	add,
	locationOutline,
	starOutline,
	star,
	syncOutline,
	close,
} from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useRouter, RouteRecordRaw } from "vue-router";
import { useStore } from "@/store";

import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import wishlistApp from "@/libs/wishlist";
import cartApp from "@/libs/cart";
import compareApp from "@/libs/comparelist";

import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import UploadNationalIdModal from "../components/UploadNationalIdModal.vue";

import { event as gtagEvent } from "vue-gtag";

export default defineComponent({
	name: "Product",
	props: ["product_id"],
	setup(props) {
		const { t, te } = useI18n();
		const router = useRouter();
		const storeX = useStore();
		const {
			createLoading,
			openToast,
			compareDate,
			formatNumberLocale,
		} = useGlobalFunctions();
		const { getLocalStorageData } = useLocalStorage();
		const productId = ref(props.product_id);
		const product = ref();
		const productSellerId = ref(null);
		const mainProductSellerName = ref(null);
		const mainProductSellercode = ref(null);
		const qty = ref(1);
		const qtySeller = ref({});
		const productCartOptions = ref({});
		const productOptions = ref({} as { [key: string]: any });
		const finalPrice = ref();
		const productGallery = ref();
		const addingToWishlist = ref(false);
		const addingToCart = ref(false);
		const addingToCompare = ref(false);
		const reviews = ref([]);
		const reviewRatings = ref([]);
		const otherSellers = ref([]);
		const smallScreen = ref(false);
		const teleportTarget = ref(null);

		const showSellerName = ref(getLocalStorageData("showSellerName"));

		const icons = reactive({
			heartOutline,
			heart,
			call,
			remove,
			add,
			locationOutline,
			starOutline,
			star,
			syncOutline,
			close,
		});

		const slideMainOpts = ref({
			initialSlide: 0,
			effect: "slide",
			speed: 800,
			autoplay: {
				delay: 2500,
			},
			height: 300,
			slidesPerView: 1,
			spaceBetween: 10,
			paginationP: false,
			navigationP: false,
			scrollbarP: false,
			loop: false,
			dir: "ltr",
			direction: "horizontal",
		});

		const slideThumbOpts = ref({
			initialSlide: 0,
			effect: "slide",
			speed: 800,
			autoplay: false,
			height: 100,
			slidesPerView: 3,
			spaceBetween: 10,
			paginationP: false,
			navigation: false,
			scrollbarP: false,
			loop: false,
			dir: "ltr",
			direction: "horizontal",
		});

		const mainSlider = ref(null);

		const selectedImage = (image) => {
			const selectedThumbImage = image.url;
			const gallerySlider = mainSlider.value.$el;
			productGallery.value.forEach((item: any, index: any) => {
				if (item.url == selectedThumbImage) {
					gallerySlider.slideTo(index);
				}
			});
		};
		const thumbnailSlider = ref(null);

		const nextSlide = async () => {
			const slide = await thumbnailSlider.value.$el.getSwiper();
			await slide.slideNext();
		};
		const prevSlide = async () => {
			const slide = await thumbnailSlider.value.$el.getSwiper();
			await slide.slidePrev();
		};

		const reviewAllowGuest = getLocalStorageData("reviewAllowGuest");

		const addReviewData = ref({
			product_id: productId.value,
			nickname: storeX.state.customerData
				? storeX.state.customerData.account_data.full_name
				: "",
			title: "",
			detail: "",
			ratings: {},
			ratingsStars: {},
		});

		const addReviewRules = {
			detail: { required },
		};

		const vAddReview = useVuelidate(addReviewRules, addReviewData) as any;

		const defaultAddressStateId = ref(null);
		const setDefaultAddressStateId = async () => {
			if (storeX.state.shippingStateId) {
				defaultAddressStateId.value = storeX.state.shippingStateId;
			} else {
				const resp = (await httpApp.sendGetRequest(
					settingsApp.getEndpointUrl("getDefaultAddresses")
				)) as any;
				if (resp.success) {
					defaultAddressStateId.value = resp.data["billing"]["region_id"];
				} else {
					defaultAddressStateId.value = false;
				}
			}
		};

		const shippingType = ref(false);
		const getShippingType = async (sellerState) => {
			if (!defaultAddressStateId.value) {
				await setDefaultAddressStateId();
			}
			//if (!defaultAddressStateId.value) return;
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getSellerShippingType"), {
					store_view: getLocalStorageData("storeCode"),
					seller_state: sellerState,
					buyer_state: defaultAddressStateId.value,
				})
				.then((response: any) => {
					if (response.success) {
						shippingType.value = response.shipping_type;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getShippingText = () => {
			let text = t("product.deliveryWithIn");
			if (shippingType.value["delivery_duration_type"] == 1) {
				text +=
					" " +
					t(
						"product.hoursCount",
						Number(shippingType.value["delivery_duration"])
					);
			} else {
				text +=
					" " +
					t(
						"product.daysCount",
						Number(shippingType.value["delivery_duration"])
					);
			}
			text += " (" + shippingType.value["name"] + ")";
			return text;
		};

		const otherShippingType = async (seller) => {
			if (!defaultAddressStateId.value) {
				await setDefaultAddressStateId();
			}
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getSellerShippingType"), {
					store_view: getLocalStorageData("storeCode"),
					seller_state: seller.state,
					buyer_state: defaultAddressStateId.value,
				})
				.then((response: any) => {
					if (response.success) {
						seller["shipping_type"] = response.shipping_type;
					} else {
						seller["shipping_type"] = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getOtherSillerShippingText = (seller) => {
			if (seller["shipping_type"]) {
				let text = t("product.deliveryWithIn");
				if (seller["shipping_type"]["delivery_duration_type"] == 1) {
					text +=
						" " +
						t(
							"product.hoursCount",
							Number(seller["shipping_type"]["delivery_duration"])
						);
				} else {
					text +=
						" " +
						t(
							"product.daysCount",
							Number(seller["shipping_type"]["delivery_duration"])
						);
				}
				text += " (" + seller["shipping_type"]["name"] + ")";
				return text;
			}
		};

		const sortOtherSellers = () => {
			// setTimeout(() => {
			//   otherSellers.value = otherSellers.value.sort((a, b) =>
			//     a.calculated_price > b.calculated_price ? 1 : -1
			//   );
			// }, 500);
			// const arranged = [];
			// otherSellers.value.forEach((seller) => {
			//   const price = seller.calculated_price;
			//   if (arranged.length) {
			//     let inserted = false;
			//     for (let i = 0; i < arranged.length; i++) {
			//       const item = arranged[i];
			//       if (price < item.calculated_price) {
			//         arranged.splice(i, 0, seller);
			//         inserted = true;
			//         break;
			//       }
			//     }
			//     if (!inserted) {
			//       arranged.push(seller);
			//     }
			//   } else {
			//     arranged.push(seller);
			//   }
			// });
			// otherSellers.value = arranged;
			//return arranged;
		};

		const getVendorById = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getVendorById"), {
					seller_id: productSellerId.value,
					store_view: storeX.state.storeCode,
				})
				.then(async (response: any) => {
					if (response.success) {
						const resp = response.vendor_data;
						mainProductSellerName.value = resp.vendor_name;
						mainProductSellercode.value = resp.vendor_code;
						getShippingType(resp.state_id);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getProduct = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("productById"), {
					store_view: getLocalStorageData("storeCode"),
					product_id: productId.value,
					get_stock: true,
				})
				.then((response: any) => {
					loading.dismiss();
					const resp = response;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						const respProduct = resp;
						gtagEvent("view_item", { event_label: respProduct.sku });
						productSellerId.value = resp.seller_id;
						const photoGallery = [];
						finalPrice.value = respProduct.final_price;
						if (
							Object.prototype.hasOwnProperty.call(respProduct, "media_gallery")
						) {
							let finishedProc = 0;
							for (
								let i = 0;
								i < respProduct.media_gallery.images.length;
								i++
							) {
								const image = respProduct.media_gallery.images[i];
								photoGallery.push({
									url:
										getLocalStorageData("websiteUrl") +
										"media/catalog/product" +
										image.file,
									caption: image.label,
								});
								finishedProc++;
								if (finishedProc == respProduct.media_gallery.images.length) {
									productGallery.value = photoGallery;
								}
							}
						}
						// respProduct.final_price = respProduct.price;
						respProduct.in_stock = respProduct.is_in_stock == "1" ? 1 : 0;
						respProduct.addToCartQty = 1;
						if (Number(respProduct.special_price)) {
							const now = new Date();
							const fromDate = respProduct.special_from_date;
							const toDate = respProduct.special_to_date;
							if (
								(!fromDate && !toDate) ||
								(!fromDate && compareDate(now, toDate) == -1) ||
								(!toDate && compareDate(now, fromDate) == 1) ||
								(fromDate &&
									toDate &&
									compareDate(now, toDate) == -1 &&
									compareDate(now, fromDate) == 1)
							) {
								const newPrice = Number(respProduct.special_price);
								respProduct.final_price = newPrice;
							}
						}
						if (!respProduct.manufacturer) {
							respProduct.manufacturer_value = false;
						}
						if (
							respProduct.attributes.length > 0 &&
							!respProduct.manufacturer
						) {
							let finishedProc = 0;
							respProduct.attributes.forEach((attribute: any) => {
								if (attribute.code == "brand") {
									respProduct.manufacturer_value = attribute.value;
								}
								finishedProc++;
								if (finishedProc == respProduct.attributes.length) {
									product.value = respProduct;
								}
							});
						} else {
							product.value = respProduct;
						}
						getVendorById();
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error);
				});
		};

		const clacPricePercentage = (product) => {
			const realPrice = product.price;
			const productFinalPrice = product.final_price;
			const percentage = ((realPrice - productFinalPrice) / realPrice) * 100;
			return percentage + "%" + t("product.off");
		};

		const getProductRating = (product) => {
			const rating = product.review_summary.rating_summary;
			if (rating > 0) {
				return rating + "%";
			}
			return 0 + "%";
		};

		const getReviews = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getProductsReviews"), {
					product_id: productId.value,
				})
				.then(async (response: any) => {
					loading.dismiss();
					const resp = response;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						reviews.value = resp.items;
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error);
				});
		};
		const getProductsReviewRatings = async () => {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("getProductsReviewRatings"),
					{
						store_view: storeX.state.storeCode,
					}
				)
				.then(async (response: any) => {
					const resp = response;
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						reviewRatings.value = resp.items;
						resp.items.forEach((item) => {
							addReviewData.value.ratings[item.rating_id] = 0;
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const getStarIcon = (option: any) => {
			const decision =
				option.code <=
				addReviewData.value.ratingsStars[option.rating_id.toString()]
					? icons.star
					: icons.starOutline;
			return decision;
		};

		const getRatingIconColor = (option: any) => {
			const decision =
				option.code <=
				addReviewData.value.ratingsStars[option.rating_id.toString()]
					? "primary"
					: "";
			return decision;
		};

		const setReviewOption = (option: any) => {
			addReviewData.value.ratings[option.rating_id.toString()] =
				option.option_id;
			addReviewData.value.ratingsStars[option.rating_id] = option.code;
		};

		const addReviewAction = async (event) => {
			event.preventDefault();
			vAddReview.value.$touch();
			if (vAddReview.value.$invalid) {
				return false;
			}
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("addProductReview"),
					addReviewData.value
				)
				.then(async (response: any) => {
					const resp = response;
					if (resp.success) {
						addReviewData.value.title = "";
						addReviewData.value.detail = "";
						addReviewData.value.ratings = {};
						addReviewData.value.ratingsStars = {};
						openToast(t("product.reviewSubmitionMsg"), "success");
						getReviews();
						loading.dismiss();
					} else {
						if (te("apiMsgs." + resp.error.code)) {
							openToast(t("apiMsgs." + resp.error.code), "danger");
							loading.dismiss();
						} else {
							openToast(resp.error.message, "danger");
							loading.dismiss();
						}
					}
				})
				.catch((error) => {
					console.log(error);
					loading.dismiss();
				});
		};

		const getWishlistItems = () => {
			wishlistApp
				.getWishlistItems()
				.then(() => {
					addingToWishlist.value = false;
				})
				.catch(() => {
					addingToWishlist.value = false;
				});
		};

		const addToWishlist = () => {
			if (!storeX.state.customerData) {
				router.push("/account/login");
			} else {
				addingToWishlist.value = true;
				wishlistApp
					.addToWishlist(product.value)
					.then(() => {
						getWishlistItems();
					})
					.catch((error) => {
						addingToWishlist.value = false;
						console.log(error);
					});
			}
		};

		const removeFromWishlist = (itemId: any) => {
			addingToWishlist.value = true;
			wishlistApp
				.removeFromWishlist(itemId)
				.then(() => {
					getWishlistItems();
					addingToWishlist.value = false;
				})
				.catch((error) => {
					addingToWishlist.value = false;
					console.log(error);
				});
		};

		const addProductToCompare = () => {
			// if (!storeX.state.customerData) {
			//   router.push("/account/login");
			// } else {
			addingToCompare.value = true;
			compareApp
				.addToCompare(product.value)
				.then(() => {
					compareApp.getProductsToCompare();
					addingToCompare.value = false;
				})
				.catch((error) => {
					addingToCompare.value = false;
					console.log(error);
				});
			// }
		};

		const removeProductToCompare = () => {
			addingToCompare.value = true;
			compareApp
				.removeFromCompare(product.value)
				.then(() => {
					compareApp.getProductsToCompare();
					addingToCompare.value = false;
				})
				.catch((error) => {
					addingToCompare.value = false;
					console.log(error);
				});
		};

		const minusQty = () => {
			if (qty.value > 1) {
				qty.value -= 1;
			}
		};

		const addQty = () => {
			qty.value += 1;
		};

		const haveQtyCart = () => {
			if (product.value.has_options == "1") {
				return 0;
			}
			if (storeX.state.cartItems["prd_" + product.value.entity_id]) {
				if (storeX.state.cartItems["prd_" + product.value.entity_id]["qty"]) {
					return storeX.state.cartItems["prd_" + product.value.entity_id][
						"qty"
					];
				}
			}
			return 0;
		};

		const convertQtyArabicToEngligh = (value, type) => {
			if (type == "main") {
				qty.value = Number(
					value.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
				);
			} else {
				qtySeller.value[type] = Number(
					value.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
				);
			}
		};

		const getProductUrl = (seller) => {
			let url = "/" + storeX.state.storeCode;
			if (seller["url_path_category"]) {
				url += "/" + seller["url_path_category"];
			} else {
				url += "/" + seller.url_path;
			}
			return url;
		};

		const nav = (path, event) => {
			event.preventDefault();
			router.push(path);
		};

		const navReplace = (path, event) => {
			event.preventDefault();
			router.replace(path);
		};

		const addToCart = async () => {
			let sentData;
			if (product.value.has_options == "1") {
				if (Object.keys(productCartOptions.value).length) {
					sentData = {
						store_view: getLocalStorageData("storeCode"),
						product_id: product.value.entity_id,
						qty: qty.value,
						super_attribute: JSON.parse(
							JSON.stringify(productCartOptions.value)
						),
					};
				} else {
					openToast(
						t("errorMsgs.pleaseSpecifyTheProductOption"),
						"danger",
						false
					);
					return;
				}
			} else {
				addingToCart.value = true;
				sentData = {
					product_id: product.value.entity_id,
					qty: qty.value,
				};
			}

			if (shippingType.value) {
				sentData["shipping_type_id"] = shippingType.value["entity_id"];
			}

			try {
				await cartApp.addToCart(sentData, true);
				addingToCart.value = false;
				//productCartOptions.value = {};
				cartApp.getQuoteItems().then(() => {
					document
						.getElementsByClassName("cart-dropdown-wrap")[0]
						.classList.add("showed");
					setTimeout(() => {
						document
							.getElementsByClassName("cart-dropdown-wrap")[0]
							.classList.remove("showed");
					}, 5000);
				});
				cartApp.getQuote();
				gtagEvent("add_to_cart", { event_label: product.value.sku });
			} catch (error) {
				addingToCart.value = false;
			}
		};

		const goToCheckOut = () => {
			router.push("/checkout");
		};

		const buyNow = async () => {
			if (qty.value == 0) {
				qty.value += 1;
			}
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			await addToCart();
			loading.dismiss();
			goToCheckOut();
		};

		const addBestSellerToCart = async (seller, event) => {
			event.preventDefault();
			let sentData = {};
			if (seller.product_type == "configurable") {
				//goToProduct(seller, false);
				//nav(getProductUrl(seller), event);
				if (Object.keys(productCartOptions.value).length) {
					sentData = {
						store_view: getLocalStorageData("storeCode"),
						product_id: seller.product_id,
						qty: qtySeller.value[seller.product_id],
						super_attribute: JSON.parse(
							JSON.stringify(productCartOptions.value)
						),
					};
				} else {
					openToast(
						t("errorMsgs.pleaseSpecifyTheProductOption"),
						"danger",
						false
					);
					return;
				}
			} else {
				sentData = {
					product_id: seller.product_id,
					qty: qtySeller.value[seller.product_id],
				};
			}

			try {
				await cartApp.addToCart(sentData, true);
				cartApp.getQuoteItems().then(() => {
					document
						.getElementsByClassName("cart-dropdown-wrap")[0]
						.classList.add("showed");
					setTimeout(() => {
						document
							.getElementsByClassName("cart-dropdown-wrap")[0]
							.classList.remove("showed");
					}, 5000);
				});
				cartApp.getQuote();
			} catch (error) {
				console.log(error, "addBestSellerToCart error");
			}
		};

		const minusSellerQty = (product_id) => {
			if (qtySeller.value[product_id] > 2) {
				qtySeller.value[product_id] -= 1;
			}
		};
		const addSellerQty = (product_id) => {
			qtySeller.value[product_id] += 1;
		};

		const getOtherSellerPrice = (seller) => {
			let price = seller.price;
			seller["calculated_price"] = price;
			Object.keys(productOptions.value).forEach((key: any) => {
				const minOption = productOptions.value[key];
				const mainValueIndex = minOption.value_index;
				if (seller["attribute_options"]) {
					seller.attribute_options.forEach((attribute: any) => {
						if (attribute.attribute_id == key) {
							attribute.values.forEach((value: any) => {
								if (value.value_index == mainValueIndex) {
									price = Number(price) + Number(value.pricing_value);
									seller["calculated_price"] = price;
								}
							});
						}
					});
				}
			});
			return price;
		};

		const getproductsothersellers = () => {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("getproductsothersellers"),
					{
						product_id: productId.value,
						store_view: storeX.state.storeCode,
					}
				)
				.then(async (response: any) => {
					if (response.success) {
						const resp = response.data;
						otherSellers.value = resp;
						for (let i = 0; i < resp.length; i++) {
							const item = resp[i];
							qtySeller.value[item.product_id] = 1;
						}
						let proceeded = 0;
						otherSellers.value.forEach((seller) => {
							otherShippingType(seller);
							getOtherSellerPrice(seller);
							proceeded++;
							if (proceeded == otherSellers.value.length) {
								sortOtherSellers();
							}
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};

		const goToVendorPage = (vendorCode) => {
			const url = "/" + storeX.state.storeCode + "/vendor/" + vendorCode;
			return url;
		};

		const goToProduct = (seller, event: any) => {
			if (event) {
				event.preventDefault();
			}
			const newRoute = {
				path: "/:lang(en|ar)/" + seller.url_key,
				name: storeX.state.storeCode + "-" + seller.url_key,
				component: () => import("../views/Product.vue"),
				props: {
					product_id: seller.product_id,
					lang: storeX.state.storeCode,
				},
			} as RouteRecordRaw;
			router.addRoute(newRoute);
			router.push({
				name: storeX.state.storeCode + "-" + seller.url_key,
			});
		};

		const disabledOptions = ref({});

		const setOptionDisabled = (
			attribute: any,
			option: any,
			hovered = false
		) => {
			return false;
			/*
      if (
        product.value["list_swatch_attr_stock_values"][option.value_index] !=
        "1"
      ) {
        disabledOptions.value[option.value_index] = true;
      }
      Object.keys(productOptions.value).forEach((key: any) => {
        const ci = productOptions.value[key];
        if (
          ci.product_super_attribute_id != option.product_super_attribute_id
        ) {
          const ciProducts =
            product.value["child_attribute_label_mapping"][
              ci.label.toLowerCase().trim()
            ]["product_ids"];
          const oProducts =
            product.value["child_attribute_label_mapping"][
              option.label.toLowerCase().trim()
            ]["product_ids"];

          const filteredArray = ciProducts.filter((value) =>
            oProducts.includes(value)
          );

          if (filteredArray.length == 0) {
            disabledOptions.value[option.value_index] = true;
            if (hovered) {
              delete productOptions.value[attribute.attribute_id];
              delete productCartOptions.value[attribute.attribute_id];
            }
          } else {
            let disabled = false;
            filteredArray.forEach((productId) => {
              if (
                product.value["children_products"][productId]["stock_data"][
                  "is_in_stock"
                ] == "0"
              ) {
                disabled = true;
              }
            });
            disabledOptions.value[option.value_index] = disabled;
          }
        }
      });
      */
		};

		const checkDisabledOtherOptions = (attribute: any) => {
			product.value.attribute_options.forEach((otherAttribute) => {
				if (otherAttribute.attribute_id != attribute.attribute_id) {
					otherAttribute.values.forEach((otherOption) => {
						setOptionDisabled(otherAttribute, otherOption, true);
					});
				}
			});
		};

		const getStockText = () => {
			let stockText = t("product.inStock");
			let stockQty = 0;
			if (product.value.type_id == "simple") {
				stockQty = product.value.stock_data.qty * 1;
			} else if (product.value["children_products"]) {
				if (Object.keys(productOptions.value).length === 0) {
					Object.keys(product.value.children_products).forEach((key: any) => {
						const child = product.value.children_products[key];
						stockQty += child.stock_data.qty * 1;
					});
				} else {
					let intersect = [];
					Object.keys(productOptions.value).forEach((key: any) => {
						const option = productOptions.value[key];
						const childAttributeLabelMapping =
							product.value["child_attribute_label_mapping"][
								option.label.toLowerCase().trim()
							];
						let productIds = [];
						if (
							childAttributeLabelMapping &&
							Object.prototype.hasOwnProperty.call(
								childAttributeLabelMapping,
								"product_ids"
							)
						) {
							productIds =
								product.value["child_attribute_label_mapping"][
									option.label.toLowerCase().trim()
								]["product_ids"];
						}
						if (intersect.length === 0 && productIds.length > 0) {
							intersect = productIds;
						} else {
							intersect = productIds.filter((value) =>
								intersect.includes(value)
							);
						}
					});

					Object.keys(product.value.children_products).forEach((key: any) => {
						const child = product.value.children_products[key];
						if (intersect.includes(child.entity_id)) {
							stockQty += child.stock_data.qty * 1;
						}
					});
				}
			}
			if (stockQty) {
				stockText += "(" + stockQty + ")";
				product.value.stock_data.is_in_stock = 1;
			} else {
				product.value.stock_data.is_in_stock = 0;
			}
			return stockText;
		};

		const optionSelected = (attribute: any, option: any) => {
			// if (
			//   product.value["list_swatch_attr_stock_values"][option.value_index] !=
			//     "1" ||
			//   disabledOptions.value[option.value_index]
			// ) {
			//   return;
			// }
			productCartOptions.value[attribute.attribute_id] = option.value_index;
			productOptions.value[attribute.attribute_id] = option;
			finalPrice.value = product.value.final_price;
			Object.keys(productOptions.value).forEach((key: any) => {
				finalPrice.value =
					Number(finalPrice.value) +
					Number(productOptions.value[key].pricing_value);
			});

			if (attribute.attribute_code == "color") {
				const selectedColor = option.admin_label;
				const gallerySlider = mainSlider.value.$el;
				productGallery.value.forEach((item: any, index: any) => {
					if (item.caption == selectedColor) {
						gallerySlider.slideTo(index);
					}
				});
			}

			setOptionDisabled(attribute, option);
			checkDisabledOtherOptions(attribute);
			getStockText();
			sortOtherSellers();
		};

		const getOtherSellerStock = (seller) => {
			let stockQty = Number(seller.stock) || 0;
			if (seller["children_products"]) {
				stockQty = 0;
				if (Object.keys(productOptions.value).length === 0) {
					Object.keys(seller.children_products).forEach((key: any) => {
						const child = seller.children_products[key];
						if (child["stock_data"]) {
							stockQty += child.stock_data.qty * 1;
						}
					});
				} else {
					let intersect = [];
					Object.keys(productOptions.value).forEach((key: any) => {
						const option = productOptions.value[key];
						const childAttributeLabelMapping =
							seller["child_attribute_label_mapping"][
								option.label.toLowerCase().trim()
							];
						let productIds = [];
						if (
							childAttributeLabelMapping &&
							Object.prototype.hasOwnProperty.call(
								childAttributeLabelMapping,
								"product_ids"
							)
						) {
							productIds =
								seller["child_attribute_label_mapping"][
									option.label.toLowerCase().trim()
								]["product_ids"];
						}
						if (intersect.length === 0 && productIds.length > 0) {
							intersect = productIds;
						} else {
							intersect = productIds.filter((value) =>
								intersect.includes(value)
							);
						}
					});

					Object.keys(seller.children_products).forEach((key: any) => {
						const child = seller.children_products[key];
						if (intersect.includes(child.entity_id)) {
							stockQty += child.stock_data.qty * 1;
						}
					});
				}
			}

			return stockQty;
		};

		const getColorImageUrl = (attribute: any, option: any) => {
			const labelMapping =
				product.value["child_attribute_label_mapping"][
					option.label.toLowerCase().trim()
				];
			let imgUrl = "assets/images/placeholder.png";
			if (labelMapping) {
				const defaultLabel =
					product.value["child_attribute_label_mapping"][
						option.label.toLowerCase().trim()
					]["default_label"];
				imgUrl =
					getLocalStorageData("websiteUrl") +
					"media/wysiwyg/swatches/" +
					defaultLabel +
					".png";
			}
			return imgUrl;
		};

		const otherPlatformPrices = ref([]);
		const getOtherPlatformsPrices = () => {
			otherPlatformPrices.value = [];
			const isActive = getLocalStorageData("PPWSActive");
			if (isActive) {
				httpApp
					.sendPostRequest(
						settingsApp.getEndpointUrl("scrapperProductPrices"),
						{
							product_id: productId.value,
						}
					)
					.then(async (response: any) => {
						if (response.success) {
							otherPlatformPrices.value = response.items;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		};

		const isSmallScreen = () => {
			const isSmall = window.innerWidth < 768;
			smallScreen.value = isSmall;
			return isSmall;
		};

		window.addEventListener("resize", () => {
			isSmallScreen();
		});

		const domainLangUrl = window.location.origin;
		const isAE = domainLangUrl.includes("ae.yallatager");

		onIonViewWillEnter(() => {
			productId.value = props.product_id;
			setDefaultAddressStateId();
			getProduct();
			getReviews();
			getProductsReviewRatings();
			getproductsothersellers();
			isSmallScreen();
			getOtherPlatformsPrices();
		});

		return {
			t,
			te,
			storeX,
			icons,
			product,
			finalPrice,
			qty,
			qtySeller,
			productGallery,
			slideMainOpts,
			slideThumbOpts,
			mainSlider,
			optionSelected,
			setOptionDisabled,
			disabledOptions,
			getColorImageUrl,
			selectedImage,
			thumbnailSlider,
			nextSlide,
			prevSlide,
			clacPricePercentage,
			addToCart,
			buyNow,
			addingToWishlist,
			addToWishlist,
			removeFromWishlist,
			addingToCart,
			reviews,
			reviewRatings,
			addQty,
			minusQty,
			addSellerQty,
			minusSellerQty,
			haveQtyCart,
			convertQtyArabicToEngligh,
			productCartOptions,
			getProductRating,
			addReviewData,
			reviewAllowGuest,
			getStarIcon,
			getRatingIconColor,
			setReviewOption,
			addReviewAction,
			nav,
			navReplace,
			goToVendorPage,
			getProductUrl,
			goToProduct,
			addProductToCompare,
			addingToCompare,
			removeProductToCompare,
			otherSellers,
			showSellerName,
			addBestSellerToCart,
			getVendorById,
			productSellerId,
			mainProductSellerName,
			mainProductSellercode,
			getStockText,
			formatNumberLocale,
			shippingType,
			getShippingText,
			getOtherSillerShippingText,
			getOtherSellerPrice,
			getOtherSellerStock,
			smallScreen,
			teleportTarget,
			otherPlatformPrices,
			isAE,
		};
	},
	components: {
		IonPage,
		IonContent,
		IonText,
		IonInput,
		IonTextarea,
		IonSlides,
		IonSlide,
		IonImg,
		IonList,
		IonItem,
		IonLabel,
		IonRow,
		IonCol,
		//IonButton,
		IonIcon,
		//IonListHeader,
		IonCard,
		IonCardHeader,
		IonCardContent,
		IonSpinner,
		MainHeader,
		MainFooter,
	},
});
